.card {
  border: 1px solid #ccc;
  margin: auto;
}

.card hr {
  border: 0;
  border-top: 1px solid #eee;
}

.expand-icon {
  transform: rotate(0deg);
}
.expanded {
  transform: rotate(180deg);
}

.card-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 32px;
}
.card-footer > :last-child {
  justify-self: end;
}

.shareSocialButtons{
  background-color: lightgrey !important;
  color: #536674 !important;
  margin-right: 10px !important;
  height: 40px;
  width: 40px;
}

.contentChips{
  border-radius: 8px !important;
  margin: 20px 8px 20px 0px !important;
  cursor: pointer !important;
}

@media(max-width : 768px){
  .contentChips{
    border-radius: 8px !important;
    margin: 10px 8px 5px 0px;
  }

  .card-footer{
    padding: 16px 5px;
  }
}