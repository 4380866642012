.app-footer{
    width: 80vw;
    margin: auto;
}

.desktop {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.columns {
    display: flex;
    max-width: 800px;
    width: 100%;
    justify-content: space-between;
}

.column-name {
    font-size: 16px;
    font-weight: 500;
}

.column-items {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: rgba(0, 0, 0, 0);
}

.column-item {
    margin-top: 12px;
}

.column-item-link {
    color: #5e5e5e;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
}

.accordion-item {
    margin: 0 !important;
}

.MuiAccordionSummary-root {
    height: 48px !important;
}
.MuiAccordionSummary-content {
    margin: 0 !important;
}

.accordion-item-list {
    padding: 0 !important;
}

.accordion-item-details {
    padding: 0 !important;
}