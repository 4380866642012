.bannerTitle{
    color : #39393A !important;
    font-size: 3.8vw !important;
    line-height: 1.2 !important;
    font-weight: 900 !important;
    margin-top: 15px !important;
    font-family: "Anton", sans-serif !important;
  }
  
  .bannerText{
    color : #39393A !important;
    font-size: 24px !important;
    line-height: 1.3 !important;
    font-weight: 500 !important;
    margin-top: 20px !important;
  }

  @media(max-width : 768px){
    .bannerTitle{
      font-size: 45px !important;
      text-align: center;
    }
    .bannerText{
      text-align: center;
    }
    .bannerIdiotImage{
      margin-left: 20%;
    }
  }