.headerButton{
    text-transform: none !important;
    color : #596C78 !important;
}

.makeSelectionButton{
    border: 1px solid #596C78 !important;
}

.secondToolbar{
    min-height: 48px !important;
}

.partSelectButton{
    height: 48px !important;
    width : 160px !important;
    border-radius: 0px !important;
}

.partSelectButton:hover{
    background-color: transparent !important;
    border-bottom: 2px solid black;
}

@media(max-width : 768px){
    
}